.site-modal {
    position: fixed; /* Stay in place */
    z-index: 10000; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
}
  /* Hot fix for login modal*/
  @media only screen and (max-width: 600px) {
    .modal-content {
      width: 100%;
    }
  }
  
  .site-modal .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
    width: 23px;
    padding: 4px;
  }
  
  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }