.swal-button.swal-button--confirm {
  background-color:  #2F4858
}

.swal2-styled.swal2-confirm  {
  background-color:  #2F4858 !important
}

.swal2-cancel.swal2-styled {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
  background-color: transparent;
  outline: 0;
  border: 0;
  /* margin: 0; */
  border-radius: 0;
  padding: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  -webkit-appearance: none;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: inherit;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  min-width: 64px;
  padding: 6px 16px;
  border-radius: 4px;
  -webkit-transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
}

/* .swal-button:not([disabled]):hover */
.swal-button.swal-button--confirm:hover 
{
  background-color:  rgb(32, 50, 61) !important
}

.swal-icon--error__line {
  background-color: #861B00; 
}

.swal-icon--error {
  border-color: #861B00;
}


.swal-icon--success__line {
  background-color: #00D670;
}

.swal-icon--success__ring {
  border-color: #00D670;
}
.swal-icon--success {
  border-color: #00D670;
}


.swal2-icon.swal2-error.swal2-icon-show {
  border-color:#861B00 !important;
  color: #861B00 !important;

}

.swal2-icon.swal2-error [class^=swal2-x-mark-line] {
  background-color: #861B00;
}

.swa2-icon--error__line {
  background-color: #861B00 !important; 
}



.swal2-icon.swal2-warning.swal2-icon-show {
  border-color:#CA8433; /*#facea8; */
  color: #A76416; /*#f8bb86;*/
}

.swal2-icon.swal2-success.swal2-icon-show {
  border-color:#00D670; 
  color: #00D670; /*#f8bb86;*/
}
.swal2-success-ring {
  border-color: #00D670 !important;
 }

.swal2-container {
  z-index: 10001 !important; /*one more leven than Modal.css*/
}

@media (orientation: portrait) {

}

@media (orientation: landscape) {

}

@media (min-aspect-ratio: 16 / 9) {

}