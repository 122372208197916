.spinner-wrapper-main{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 40%;
    color: #00B497;

}
 

.spinner-wrapper {
    text-align: center;
    padding-top: 40px;
    padding-bottom: 20px;
    color: #00B497;
    /* z-index: 2000; */
}